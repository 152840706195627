import React, {useEffect, useRef, useState} from 'react';
import FullScreen from "../../components/FullScreen";
import { getAllWorks } from '../../requests/works';
import WorkComponent from './components/WorkComponent';
import "./style.css";
const Work = ({innerRef, isMobile}) => {
    const aRef = useRef(null);
    const [works, setWorks] = useState([])
    innerRef(aRef);
    useEffect(() => {
        fetchData();
        
    }, [])
    const fetchData = async () => {
        const data =  await getAllWorks();
        setWorks(data);
    }
    return(
        <div ref={aRef}>
            <FullScreen content={
                <div>
                    <div className="flex-center light-mo">
                        Timelines
                    </div>
                    <div style={{margin: isMobile ? 10 : '0 20vw'}}>
                        <div style={styles.scrollbars} id="style-11">
                        {!!works && works.length && works.map(work => <WorkComponent work={work}/> )}
                        </div>
                    </div>
                </div>
                
            }
            background={"background-dark work-page"}
            />
        </div>
    )
}
const styles = {
    scrollbars: {
        overflowY: 'scroll',
        maxHeight: '60vh',
    },
}
export default Work;
