import React from 'react';
import {
    useTranslation
} from "react-i18next";
import imageWork from '../img/work.jpg';
const Footer = ({
    isMobile
}) => {
    const { t } = useTranslation();

    return (
    <div className="background-dark p-md-5">
        <div className="d-flex flex-wrap">
        <div className={`${isMobile ? 'col-12' : 'col-5'} flex-center`}>
            <h2>
            {t('footer.newestProject')}
            </h2>
            <a href="https://khoahoc.hiephoang.net" target="_blank">
            <img src={imageWork} width="300" height="200"></img>
            </a>
        </div> 
        <div className={isMobile ? 'col-12' : 'col-3'}>
            <h2>
                {t('footer.service')}
            </h2> 
            <ul>
                <li>
                {t('footer.booking')}
                </li>
                <li>
                {t('footer.bussinessCustomer')}
                </li>
                <li>
                {t('footer.donate')}
                </li>
            </ul>
        </div> 
        <div className={isMobile ? 'col-12' : 'col-4'}>
            <h2>
                {t('footer.contact')}
            </h2> 
            <ul>
                <li>
                {t('footer.address')}
                </li>
                <li>
                {t('footer.email', {emailAdmin: "admin@hiephoang.net"})}
                </li>
                <li>
                {t('footer.hotline', {phoneNumber: "0839.199.868"})}
                </li>
            </ul>
        </div> 
        </div>
        <footer style={styles.copyRight} className="flex-center m-lg-5">{t('footer.copyright', {year: 2023})} <a href="https://www.facebook.com/trung.duc.1481169" target="_blank">Keid</a></footer>
    </div>)
}
const styles = {
    copyRight: {
        fontSize: 12,
        paddingBottom: 20
    },

}
export default Footer;