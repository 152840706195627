import React from "react";
import { useEffect, useState } from "react";
import { getI18n, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ENVIRONMENTS } from "../config/env";
function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
      width,
      height
  };
}
const NAVBAR_HEIGHT = 65;
const NavigationBar = ({ onPress, isWelcome, isHome }) => {
  const i18n = getI18n();
  const { t } = useTranslation();
  const { height } = getWindowDimensions();

  function onScroll() {
    setScrollY(window.pageYOffset);
    if(scrollY >= height - NAVBAR_HEIGHT + 40) {
      setPosition('sticky-top')
    }
    if(scrollY < height - NAVBAR_HEIGHT + 40) {
      setPosition('bottom')
    }
  }
  useEffect(() => {
    function watchScroll() {
      window.addEventListener("scroll", onScroll);
    }
    isHome && watchScroll();
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  });
  const [scrollY, setScrollY] = useState(0);
  const [position, setPosition] = useState(isHome ? 'bottom' : 'sticky-top');
  return (
    <nav className={`main-nav ${position}`}>
      {isWelcome && position !== 'sticky-top' && (
        <div className="text-white pt-5">
          <p>{t("scrollDown")}</p>
          <i className="fa fa-arrow-down"></i>
        </div>
      )}
      <div className={"bg-brand-color flex-center"} style={{minHeight: NAVBAR_HEIGHT+'px'}}>
      <ul>
        <li key={1}>
          <a title={t("menu.about")} onClick={() => onPress(1)}>
            {t("menu.about")}
          </a>
        </li>
        <li key={2}>
          <a title={t("menu.works")} onClick={() => onPress(2)}>
            {t("menu.works")}
          </a>
        </li>
        <li key={3}>
          <a title={t("menu.contact")} onClick={() => onPress(3)}>
            {t("menu.contact")}
          </a>
        </li>
        <li key={4}>
        <Link to="/blog">
            {t("menu.blog")}
            </Link>
        </li>
        <li key={3}>
          <a title={t("menu.contact")} href={ENVIRONMENTS.CTA_LINK} target="_blank">
            {t("menu.cta")}
          </a>
        </li>
        <li>
          <a onClick={() => i18n.changeLanguage("vn")}>vn</a>
          <span>{"/"}</span>
          <a onClick={() => i18n.changeLanguage("en")}>en</a>
        </li>
      </ul>
      </div>
      
      
    </nav>
  );
};

export default NavigationBar;
