let CONSTANTS = {
    JOB_TITLE: 'SOFTWARE DEVELOPER & FOUNDER HP NETWORK',
    SERVICE_EMAILJS_ID: 'service_j4hrnq8',
    USER_EMAIL_JS_ID: 'user_GypxSMftqt5WSvS82WDFa',
    TEMPLATE_ID: 'template_1o00b7h',
    USERNAME_TIKTOK: 'REACT_APP_USERNAME_TIKTOK',
    PASSWORD_TIKTOK: 'REACT_APP_PASSWORD_TIKTOK',
}

export {
    CONSTANTS
};