import React from "react";

const ToggleMainMenu = ({ onPress }) => {
  return (
    <div className="toggle-main-menu">
      <div onClick={onPress} class="navbar-toggler third-button">
        <div class="animated-icon3">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </div>
  );
};
export default ToggleMainMenu;
