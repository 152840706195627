import React from "react";
import NavigationBar from "./NavigationBar";

const MainMenu = ({ isShow, onPress, closeMenu }) => {
  let cl = isShow ? "main-menu open" : "main-menu d-none";
  return (
    <div className={cl} onClick={closeMenu}>
      <NavigationBar onPress={onPress} />
    </div>
  );
};
export default MainMenu;
