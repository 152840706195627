import React, {useRef} from 'react';
import "./style.css";
import Ellipse from "../../../svg/work-Ellipse.svg";
import { formatDate } from '../../../services/util';

const WorkComponent = ({onPress, work, onHover}) => {
    const aRef = useRef(null);
    
    const startDate = formatDate(work.startDate);
    const endDate = formatDate(work.endDate);
    return(
        <div ref={aRef} className="d-flex" style={{marginBottom: '35px', width: '100%'}}>
            <div className="flex-between">
                <div>
                    <img  style={{width: '22px', height: '22px'}} src={Ellipse} alt="work title" />
                    <div className="line-work"></div>
                </div>
                <div className="w-100">
                    <p className="color-work-yellow">{startDate} - {endDate}</p>
                    <div style={styles.backgroundDescription} className="work-content">
                        <p>{work.title}</p>
                        <p>{work.description}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

const styles = {
    backgroundDescription: {
        background: '#fff',
        color: 'black',
        borderRadius: 10,
        padding: 10,
        width: '50vw'
    }
}
export default WorkComponent;