import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'

export const HightlightPost = ({ blogs, imageDefault }) => {
    return (
        <Row>
            {blogs && blogs.map((item, index) => {
                if (index > 0 && index < 5) {
                    return (<Col sm="12" md="6" lg="3" key={index} >
                        <Link to={`/blog/${item.slug}`}>
                            <img className="col-12 p-0" src={item.cover || imageDefault} alt="img-alt"></img>
                            <h1 className="text-center">{item.title}</h1>
                            <p>{item.description}</p>
                        </Link>
                    </Col>)
                }
            }
            )}
        </Row>
    )
}
