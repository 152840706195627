import React, { useState, useEffect, } from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from "react-router-dom";
import NavigationBar from '../../../components/NavigationBar';
import ToggleMainMenu from '../../../components/ToggleMainMenu';
import { getAllBlogs, getPost, getPostBySlug } from '../../../requests/blogs';
import imageWork from '../../../img/work.jpg';
import { TitleMenu } from '../components/TitleMenu';
import SocialContact from '../../../components/SocialContact';
import Contact from '../../Contact';
import MainMenu from '../../../components/MainMenu';
import { MostViewedPosts } from '../components/MostViewedPosts';
import { HightlightPost } from '../components/HightlightPost';
import { formatDate } from '../../../services/util';
import { RelatedPosts } from '../components/RelatedPosts';
import { useTranslation } from 'react-i18next';

export const DetailBlog = (props) => {
  const { isMobile } = props
  const [post, setPost] = useState({});
  const [showMenu, setShowMenu] = useState(false)
  const [blogs, setBlogs] = useState([])
  const [categories, setCats] = useState([])

  const location = useLocation();

  const postSlug = location.pathname.split("/")[2];

const {t} = useTranslation()
  useEffect(() => {
    fetchData();
  }, [])
  const fetchData = async () => {
    const data = await getAllBlogs();
    setBlogs(data);
  }
  
  useEffect(() => {
      window.scrollTo(0, 0);
    return () => {
    }
  }, [location]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getPostBySlug(postSlug);
        setPost(res);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [postSlug]);
  return (
    <div className="site-wrapper blog-container">
      {isMobile ? <ToggleMainMenu onPress={() => setShowMenu(!showMenu)} /> : <NavigationBar isMobile={isMobile} isWelcome={false} isHome={false} onPress={(id) => {
        window.location.replace("/");
      }} />}

      <Container className="">
               <HightlightPost blogs={blogs} imageDefault={imageWork} />

        <hr class="solid"></hr>
        <Row className="justify-content-md-center">
          <Col md="8">
            <Image className="w-100" style={{ objectFit: 'cover' }} src={post.cover || imageWork} alt="img-alt"></Image>
            <div className='text-center'>
              <h1>{post.title}</h1>
              <span>{formatDate(post.date_posted)}</span>
              <p>{post.author}</p>
              <p>{post.body}</p>
            </div>
            <hr class="solid"></hr>
           <RelatedPosts blogs={blogs} imageDefault={imageWork} />
          </Col>
          <Col xs lg="4">
            <TitleMenu title={t('menu.contact')}>
              <SocialContact />
            </TitleMenu>
            <MostViewedPosts blogs={blogs} imageDefault={imageWork} />
            <TitleMenu title={t('blog.category')}>
              {categories.map((item, index) => <div key={index}>{item.title}</div>)}
            </TitleMenu>
          </Col>
        </Row>
      </Container>


      <Contact
        isMobile={window.innerWidth <= 768}
        onPress={() => { }}
        innerRef={(about) => { }}
      />
      {isMobile && (
        <MainMenu
          closeMenu={() => setShowMenu(!showMenu)}
          isShow={showMenu}
          onPress={(id) => {
            setShowMenu(!showMenu);
            window.location.replace("/");
          }}
        />
      )}
    </div>
  )
}
