import React, {StyleSheet} from "react";
import tiktok from "../svg/tiktok.svg";

const fb = "https://www.facebook.com/hiep.hoanghp";
const ig = "https://www.instagram.com/hiep_hoang04711";
const git = "https://github.com/hiepphit";
const li = "https://www.linkedin.com/in/hubert-phamhiep";
const tt = "https://tiktok.com/@hiep_hoang";
const tl = "https://t.me/hiepphit";
const yt = "https://www.youtube.com/@hoanghiepOfficial";
const ma = "mailto:hiepph.coder@gmail.com";

const SocialContact = ({ onPress, listItem, isWelcome }) => {
  return (
    <ul style={isWelcome ? styles.welcome : styles.normal} className="social-contact">
      <li>
        <a
          title={"facebook"}
          id="fb"
          className={"btn btn-secondary"}
          href={fb}
          target="_blank"
          rel="noreferrer"
        >
          <i className={"fa fa-facebook"}></i>
        </a>
      </li>
      <li>
        <a
          title={"tiktok"}
          id="tt"
          className={"btn btn-secondary"}
          href={tt}
          target="_blank"
          rel="noreferrer"
        >
          <img className={"tt-icon"} alt="icon menu mobile" src={tiktok} />
        </a>
      </li>
      <li>
        <a
          title={"youtube"}
          id="yt"
          className={"btn btn-secondary"}
          href={yt}
          target="_blank"
          rel="noreferrer"
        >
          <i className={"fa fa-youtube"}></i>
        </a>
      </li>
      <li>
        <a
          title={"instagram"}
          id="ig"
          className={"btn btn-secondary"}
          href={ig}
          target="_blank"
          rel="noreferrer"
        >
          <i className={"fa fa-instagram"}></i>
        </a>
      </li>
      <li>
        <a
          title={"github"}
          id="git"
          className={"btn btn-secondary"}
          href={git}
          target="_blank"
          rel="noreferrer"
        >
          <i className={"fa fa-github"}></i>
        </a>
      </li>
      <li>
        <a
          title={"linked in"}
          id="li"
          className={"btn btn-secondary"}
          href={li}
          target="_blank"
          rel="noreferrer"
        >
          <i className={"fa fa-linkedin"}></i>
        </a>
      </li>

      <li>
        <a
          title={"telegram"}
          id="tl"
          className={"btn btn-secondary"}
          href={tl}
          target="_blank"
          rel="noreferrer"
        >
          <i className={"fa fa-telegram"}></i>
        </a>
      </li>

      <li>
        <a
          title={"email"}
          id="ma"
          className={"btn btn-secondary"}
          href={ma}
          target="_blank"
          rel="noreferrer"
        >
          <i className={"fa fa-google"}></i>
        </a>
      </li>
    </ul>
  );
};
const styles = {
  welcome: {
    marginTop: '80%'
  },
  normal: {
    width: '100%'
  }
}
export default SocialContact;
