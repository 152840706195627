import React from 'react'
import { Col, Image, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'

export const RelatedPosts = ({ blogs, imageDefault }) => {
    return (
        <> {blogs.map((item, index) => <Link to={`/blog/${item.slug}`}>
            <Row className="mb-lg-4 mb-md-3 mb-sm-2" key={index}>
                <Col sm="12" md="6" lg="7" ><div className="w-100 h-100">
                    <Image className="w-100 h-100" style={{ objectFit: 'cover' }} src={item.cover || imageDefault} alt="img-alt"></Image>
                </div></Col>
                <Col>
                    <h1>{item.title}</h1>
                    <p>{item.description}</p>
                </Col>

            </Row>
        </Link>)}
        </>
    )
}
