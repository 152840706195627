import React, { useRef, useState } from "react";
import FullScreen from "../components/FullScreen";
import emailjs from "emailjs-com";
import { CONSTANTS } from "../constants";
import { useTranslation } from "react-i18next";
const Contact = ({ innerRef, listItem, onPress, isMobile }) => {
  const { t } = useTranslation();
  const aRef = useRef(null);
  innerRef(aRef);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const submitEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        CONSTANTS.SERVICE_EMAILJS_ID,
        CONSTANTS.TEMPLATE_ID,
        e.target,
        CONSTANTS.USER_EMAIL_JS_ID
      )
      .then(
        (result) => {
          console.log(result);
        },
        (error) => {
          console.log(error);
        }
      );
    resetForm();
  };

  const resetForm = () => {
    setName("");
    setMessage("");
    setEmail("");
    setSubject("");
  };
  return (
    <div ref={aRef}>
      <FullScreen
        content={
          <div className="flex-center pd10">
            <div className="background-dark contact-box flex-center">
              <div className="unique-logo-contact">
                <div className="contact-hub-title">Contact</div>
                <div className="contact-content-wrapper background-normal">
                  <span className="hub-contact text-color-black fpt-color">hub</span>
                </div>
              </div>
              <form
                id="contact-form"
                onSubmit={(e) => submitEmail(e)}
                method="POST"
              >
                <div className="form-group">
                  <div className="row">
                    <div className="col-md-6">
                      <label for="from_name">{t("contact.yourName")}</label>
                      <input
                        placeholder="Name"
                        id="from_name"
                        name="from_name"
                        type="text"
                        className="form-control"
                        required
                        value={name}
                        onChange={(event) => setName(event.target.value)}
                      />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="reply_to">{t("contact.email")}</label>
                      <input
                        placeholder="Email"
                        type="email"
                        name="reply_to"
                        id="reply_to"
                        className="form-control"
                        aria-describedby="emailHelp"
                        required
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="reply_to">{t("contact.subject")}</label>
                  <input
                    placeholder="Subject"
                    id="to_name"
                    type="text"
                    className="form-control"
                    required
                    value={subject}
                    onChange={(event) => setSubject(event.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="reply_to">{t("contact.content")}</label>
                  <textarea
                    placeholder="Message"
                    name="message"
                    id="message"
                    className="form-control"
                    rows="4"
                    required
                    value={message}
                    onChange={(event) => setMessage(event.target.value)}
                  />
                </div>
                <div className="text-center">
                  <button
                    type="submit"
                    className="btn btn-outline-warning align-self-center"
                  >
                    {t("contact.sendToMe")}
                  </button>
                </div>
              </form>
            </div>
          </div>
        }
        background={`background-white contact-page`}
      />
    </div>
  );
};
export default Contact;
