import React from 'react'
import { Col, Image, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { TitleMenu } from './TitleMenu'

export const MostViewedPosts = ({ blogs, imageDefault }) => {
  const {t} = useTranslation()
  return (
    <TitleMenu title={t('blog.mostView')}>
      {blogs && blogs.map((item, index) => <div className="mt-lg-4 mt-md-3 mt-sm-1" key={index}><Link to={`/blog/${item.slug}`}><Row >
        <Col xs="5" style={{ height: 80 }} >
          <Image className="w-100 h-100" style={{ objectFit: 'cover' }} src={item.cover || imageDefault} alt="img-alt"></Image></Col>
        <Col>
          <strong>{item.title}</strong>
          <p>{item.description}</p>
        </Col>
      </Row></Link></div>)}
    </TitleMenu>
  )
}
