import React, { useState, useEffect } from "react";
import "./style.css";
function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}
const FullScreen = (props) => {
  const { content, background } = props;
  const [height, setHeight] = useState(getWindowDimensions().height);
  useEffect(() => {
    const isMobile = getWindowDimensions().width <= 768;
    if (!isMobile) {
      function handleResize() {
        setHeight(getWindowDimensions().height);
      }

      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }

  }, []);
  return (
    <div
      className={"full-screen " + background}
      style={{ height: height + "px" }}
    >
      {content}
    </div>
  );
};

export default FullScreen;
