import React, { useRef, useState } from 'react';
import FullScreen from "../components/FullScreen";
import { useTranslation } from "react-i18next";
const About = ({ innerRef, listItem, onPress, isMobile }) => {
const aRef = useRef(null);
    const { t } = useTranslation();
 
    innerRef(aRef);
    return (
        <FullScreen
      content={
        <div className="flex-col pd30" ref={aRef}>
          <div className={`title ${!isMobile ? 'about-title' : ''}`}>
          {t("menu.about")}
          </div>
          <div className="pd30" style={styles.scrollbars}>
          <p>Hi, my name is Hiep. You can call me Kelvin Hoang. I'm start with Information Systems bachelor and Software Engineering major at FPT University Vietnam.
                Scholarship 100% for 4 years. Pass the scholarship exam with highest point (102/105). This exam about IQ logic and social discourse essay. 
                </p>
                <a target="_blank" rel="noreferrer" href="https://hanoi.fpt.edu.vn/chang-trai-hot-boy-dai-hoc-fpt-ha-noi-voi-thanh-tich-hoc-tap-dang-kinh-ngac.html">More about my adventure in FPT University</a>
                <p>Chào mọi người, mình là Phạm Hoàng Hiệp, cựu sinh viên khoá 11, chuyên ngành Kỹ Thuật Phần Mềm Trường Đại Học FPT Hà Nội. Mình vào ĐH FPT với số điểm 102/105, giành được học bổng 100% cho toàn thời gian học. Đồng thời là cựu học sinh trường THPT Vĩnh Bảo – Hải Phòng. Mình đinh viết phần giới thiệu này bằng tiếng Anh. Nhưng vì bố mẹ mình vào đọc không hiểu nên tôi đã viết lại bằng ngôn ngữ mẹ đẻ để mọi người dễ hiểu hơn. Để mà nói về bản thân mình thì ...</p>
               
                    <p>Cũng khá là tự hào khi xuất hiện ở trên nhiều ấn phẩm và website của ĐH FPT. Và bởi vì đội ngũ tuyển sinh, PR, Marketing của ĐH FPT rất mạnh, nên có thể nói độ phủ sóng hồi xưa của mình khá rộng. Nhờ đó mà mình có cơ hội quen rất nhiều bạn học sinh khắp mọi miền</p>
                <p>Mình từng ôn thi Học bổng đầu vào ĐH FPT cho rất nhiều lứa học sinh. Đó là một quãng thời gian rất nhiều kỷ niệm cùng những thế hệ tương lai của FPT. Phát hiện ra nhiều siêu nhân, hy vọng sau này các em sẽ có một tương lai tốt và nếu có thể thì khi anh Start-up hãy về đầu quân nếu tin tưởng anh ^^</p>
          
          </div>
        </div>
      }
      background={"background-dark about-page text-white"}
    />
    )
}
const styles = {
    scrollbars: {
        overflowY: 'auto',
        maxHeight: '80vh',
        textAlign: 'justify'
    },
}
export default About;
