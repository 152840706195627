import React, { useRef } from 'react';

const Album = ({ innerRef, listItem, onPress, isMobile }) => {
    const aRef = useRef(null);
    innerRef(aRef);
    return (
      <div ref={aRef} className={"background-dark album-page"}>
            <div className={"min-height"}>
              Album
            </div>
          
      </div>
    );
}
export default Album;
