import axios from 'axios';
import {get} from 'lodash';
import { ENVIRONMENTS } from '../config/env';

export const getAllBlogs = async accessToken => {
    const API_URL = ENVIRONMENTS.BASE_URL;
    try {
      const request = await axios({
        url: `${API_URL}/blog/posts`,
        method: 'GET',
        headers: {},
        data: {
          accessToken,
        },
      });
      return get(request, 'data', {});
    } catch (error) {
      throw error;
    }
};

export const getPost = async postId => {
    const API_URL = ENVIRONMENTS.BASE_URL;
    try {
      const request = await axios({
        url: `${API_URL}/blog/post/${postId}`,
        method: 'GET',
        headers: {},
        data: {},
      });
      return get(request, 'data', {});
    } catch (error) {
      throw error;
    }
};
export const getPostBySlug = async postSlug => {
    const API_URL = ENVIRONMENTS.BASE_URL;
    try {
      const request = await axios({
        url: `${API_URL}/blog/postSlug/${postSlug}`,
        method: 'GET',
        headers: {},
        data: {},
      });
      return get(request, 'data', {});
    } catch (error) {
      throw error;
    }
};