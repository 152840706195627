import React from 'react'

export const TitleMenu = (props) => {
    const {children, title} = props
  return (
    <div className='mb-lg-5 mb-sm-1 mb-md-2'>
        <div className='title-menu flex-center mt-2 mt-lg-0'>{title}</div>
        <div>{children}</div>
    </div>
  )
}
