import React from "react";

const ScrollToTop = () => {
  return (
    <div className={"ic-to-top"} onClick={() => {window.scrollTo({ top: 0, behavior: "smooth" });}}>
      <i className="fa fa-angle-double-up"></i>
    </div>
  );
};

export default ScrollToTop;
