import React from "react";
import FullScreen from "../../components/FullScreen";
import "../../pages/style.css";
import { useTranslation } from "react-i18next";
import SocialContact from "../../components/SocialContact";

const WelcomePage = ({ onPress, isMobile }) => {
  const { t } = useTranslation();
  return (
  
    <FullScreen
    isMobile={isMobile}
    
      content={
          <>
        <div className="flex-center">

          <div className="flex-center txt-bold title-welcome-page">
            <span>{t("title")}</span>
          </div>
          {isMobile && <SocialContact isWelcome={true} />}
        </div>
    </>

      }
      background={"background-dark cover-page text-white"}
    />
  );
};

export default WelcomePage;
