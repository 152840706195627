import "../../main.css";
import React, { useEffect, useRef, useState } from "react";
import Contact from "../Contact";
import NavigationBar from "../../components/NavigationBar";
import { getAllBlogs } from "../../requests/blogs";
import MainMenu from "../../components/MainMenu";
import ToggleMainMenu from "../../components/ToggleMainMenu";
import { TitleMenu } from "./components/TitleMenu";
import SocialContact from "../../components/SocialContact";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { Link } from "react-router-dom";
import Col from 'react-bootstrap/Col';
import './style.css'
import imageWork from '../../img/work.jpg';
import Image from 'react-bootstrap/Image';
import { MostViewedPosts } from "./components/MostViewedPosts";
import { HightlightPost } from "./components/HightlightPost";
import { RelatedPosts } from "./components/RelatedPosts";
import { useTranslation } from "react-i18next";

const Blogs = () => {
  const isMobile = window.innerWidth < 768;
  const [showMenu, setShowMenu] = useState(false)
  const [blogs, setBlogs] = useState([])
  const [categories, setCats] = useState([])
  const {t} = useTranslation()
  useEffect(() => {
    fetchData();
  }, [])
  const fetchData = async () => {
    const data = await getAllBlogs();
    setBlogs(data);
  }

  return (
    <div className="site-wrapper blog-container">
      {isMobile ? <ToggleMainMenu onPress={() => setShowMenu(!showMenu)} /> : <NavigationBar isMobile={isMobile} isWelcome={false} isHome={false} onPress={(id) => {
        window.location.replace("/");
      }} />}

      <Container className="">
        {blogs && blogs[0] && (
          <Col className="p-sm-0">
            <Link to={`/blog/${blogs[0].slug}`}>
              <div className="w-100" style={{ height: '60vh' }}>
                <Image className="w-100 h-100" style={{ objectFit: 'cover' }} src={blogs[0].cover || imageWork} alt="img-alt"></Image>
              </div>
              <h1 className="text-center ps-3">{blogs[0].title}</h1>
              <p className="text-center ps-3">{blogs[0].description}</p>
            </Link>
          </Col>
        )}

        <hr class="solid"></hr>
       <HightlightPost blogs={blogs} imageDefault={imageWork} />
        <hr class="solid"></hr>
        <Row className="justify-content-md-center">
          <Col md="8">
                      <RelatedPosts blogs={blogs} imageDefault={imageWork} />

          </Col>
          <Col xs lg="4">
            <TitleMenu title={t('menu.contact')}>
              <SocialContact />
            </TitleMenu>
            <MostViewedPosts blogs={blogs} imageDefault={imageWork} />
            <TitleMenu title={t('blog.category')}>
              {categories.map((item, index) => <div key={index}>{item.title}</div>)}
            </TitleMenu>
          </Col>
        </Row>
      </Container>


      <Contact
        isMobile={window.innerWidth <= 768}
        onPress={() => { }}
        innerRef={(about) => { }}
      />
      {isMobile && (
        <MainMenu
          closeMenu={() => setShowMenu(!showMenu)}
          isShow={showMenu}
          onPress={(id) => {
            setShowMenu(!showMenu);
            window.location.replace("/");
          }}
        />
      )}
    </div>
  );
}

export default Blogs;
