import "../../main.css";
import React, { useEffect, useRef, useState } from "react";
import WelcomePage from "./WelcomePage";
import About from "../About";
import Work from "../../pages/timelines/Work";
import Contact from "../Contact";
import Album from "./Album";
import ToggleMainMenu from "../../components/ToggleMainMenu";
import MainMenu from "../../components/MainMenu";
import NavigationBar from "../../components/NavigationBar";
import Footer from "../../components/Footer"

const useMountEffect = (fun) => useEffect(fun, [fun]);

const Home = () => {
  let screenRef = useRef([]);
  let [showMenu, setShowMenu] = useState(false);
  const [offset, setOffset] = useState(0);
  useEffect(() => {
    window.onscroll = () => {
      setOffset(window.pageYOffset);
    };
  }, []);
  const executeScroll = (id) => {
    if (id) {
      screenRef.current[id - 1]?.current?.scrollIntoView({
        behavior: "smooth",
      });
    }
  };
  useMountEffect(executeScroll);
  const [width, setWidth] = useState(window.innerWidth);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  let isMobile = width <= 768;
  return (
    <div className="site-wrapper">
      {isMobile ? <ToggleMainMenu onPress={() => setShowMenu(!showMenu)} /> : <NavigationBar isHome isWelcome={true} onPress={(id) => {
            setShowMenu(!showMenu);
            executeScroll(id);
          }} />}
      <WelcomePage onPress={executeScroll} isMobile={isMobile} />
      <About
        isMobile={isMobile}
        onPress={executeScroll}
        innerRef={(about) => (screenRef.current[0] = about)}
      />
      <Work
        isMobile={isMobile}
        onPress={executeScroll}
        innerRef={(about) => (screenRef.current[1] = about)}
      />
      <Contact
        isMobile={isMobile}
        onPress={executeScroll}
        innerRef={(about) => (screenRef.current[2] = about)}
      />
      <Album
        isMobile={isMobile}
        onPress={executeScroll}
        innerRef={(about) => (screenRef.current[3] = about)}
      />
      
      {isMobile && (
        <MainMenu
          closeMenu={() => setShowMenu(!showMenu)}
          isShow={showMenu}
          onPress={(id) => {
            setShowMenu(!showMenu);
            executeScroll(id);
          }}
        />
      )}
    </div>
  );
}

export default Home;
