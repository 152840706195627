import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import i18n from "./translation/i18n";
import { I18nextProvider } from "react-i18next";
import Home from "./pages/home/Home";
import Blogs from "./pages/blogs/Blogs";
import ScrollToTop from "./components/ScrollToTop";
import NotFound from "./components/NotFound";
import { BrowserRouter as Router, Route, Routes, Outlet } from 'react-router-dom';
import Footer from "./components/Footer";
import { DetailBlog } from "./pages/blogs/detail/DetailBlog";

require("dotenv").config();
const Layout = ({children}) => {
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  let isMobile = width <= 768;
  return (
    <>
      {children}
      <Footer isMobile={isMobile} />
      <ScrollToTop />
    </>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <Router>
        <Routes>
           <Route exact path="/" element={<Layout children={<Home />}/>} />
           <Route exact path="/blog" element={<Layout children={<Blogs />}/>} />
           <Route exact path="/blog/:slug" element={<Layout children={<DetailBlog />}/>} />
           <Route exact path="*" element={<Layout children={<NotFound />}/>} />
        </Routes>
      </Router>
    </I18nextProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
